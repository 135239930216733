import React from 'react';

function NewPage() {
  return (
    <div>
      <h1>New Page</h1>
      <p>Welcome to the new page!</p>
    </div>
  );
}

export default NewPage;